.page_settings .div_show_btn{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.page_settings .div_show_btn:nth-child(n + 2) {
    padding-top: 20px;
    margin-top: 18px;
    border-top: 2px solid #ebebeb;
}
.page_settings .div_color{
    width: auto;
    height: auto;
    padding: 10px 20px;
    cursor: pointer;
    color: #ffffff;
}