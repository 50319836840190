
.page_students .Comp_Input .cep{
    width: 80px;
    text-align: center;
}
.page_students .Comp_Input .state{
    width: 40px;
    text-align: center;
    text-transform: uppercase;
}
.page_students .Comp_Input .number{
    width: 80px;
    text-align: center;
}