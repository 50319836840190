.Comp_Topic{    
    width: 100%;
    height: auto;
}
.Comp_Topic .new_block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E2E8F0;
    flex-wrap: wrap;
    gap: 10px;
    min-height: 40px;
}

.Comp_Topic .new_block .title_page{
    font-size: 26px;
    font-weight: 500;
    font-family: 'bebas neue';
    line-height: normal;
}
.Comp_Topic .new_block .list_opt_alt_page{    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    color: #E51D4D;
    border: none;
}
.Comp_Topic .new_block .list_opt_alt_page .div_add_new_data{
    position: relative;
    width: auto;
    min-width: 150px;
    text-align: center;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #ffffff;
    border: none;
    padding: 0;
    font-size: 14px;
    font-family: 'Poppins';
    background-color: #3aa856;    
}
.Comp_Topic .new_block .list_opt_alt_page .div_add_new_data .new_data_add{
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease-in-out;
    background-color: #0e8d2e;
}
.Comp_Topic .new_block .list_opt_alt_page .div_add_new_data .name_btn_add{
    width: calc(100% - 40px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease-in-out;
    overflow: hidden;
}
.Comp_Topic .new_block .list_opt_alt_page .div_add_new_data:hover .new_data_add{
    width: 100%;
}
.Comp_Topic .new_block .list_opt_alt_page .div_add_new_data:hover .name_btn_add{
    width: 0;
    padding-left: 0px;
}

@media only screen and (max-width: 800px) {
    .Comp_Topic .new_block .title_page{
        font-size: 20px;
        font-weight: 500;
    }
}