.inf_dashboard{
    gap: 30px;
}
.inf_dashboard .div_show_year{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    padding-bottom: 10px;
    margin-bottom: 40px;
    border-bottom: 1px solid #2d3c53;
}
.inf_dashboard .div_show_year .arrow{
    
}
.inf_dashboard .div_show_year .arrow .left{
    transform: rotate(180deg);
    transition: .5s;
}
.inf_dashboard .div_show_year .arrow .icons{
    
}
.inf_dashboard .div_show_year .show_year{
    font-size: 26px;
    font-weight: 500;
    font-family: 'bebas neue';
    line-height: normal;
}
.inf_dashboard .list_data{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
.inf_dashboard .list_data .div_inf_data{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border: 1px solid;
    border-radius: 8px;
}
.inf_dashboard .list_data .div_inf_data .qtd{
    width: calc(100% - 40px);
    min-width: 300px;
    font-size: 42px;
    line-height: 42px;
    font-family: 'inter';
    padding: 20px 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.inf_dashboard .list_data .div_inf_data .title{
    width: 100%;
    height: 40px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    font-family: 'inter';
    font-size: 18px;
    line-height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 1px solid;
    color: #ffffff;
}
.inf_dashboard .div_grafic{
    width: calc(100% - 40px);
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    margin-top: 30px;
}