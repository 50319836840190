.PopUp .Color{
    width: 300px;
    margin: 0 auto;
    color: #000000;
}
.PopUp .Color .InputColor{ 
    width: calc(100% - 60px);
    padding: 30px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .Color .InputColor input {    
    border: 2px solid #ebebeb;
    border-radius: 6px;
    font-size: 14px;
    padding: 12px 10px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
}
.PopUp .Color .InputColor .div_saturation{    
    width: 240px;
    height: 146px;
    position: relative;
    margin-bottom: 20px;
}
.PopUp .Color .InputColor .w-color-saturation{
    width: 240px !important;
    height: 146px !important;
    border-radius: 8px !important;
}
.PopUp .Color .InputColor .div_hue{
    position: relative;
    margin-bottom: 16px;
}
.PopUp .Color .InputColor .hue-picker{
    width: 240px !important;
}
.PopUp .Color .InputColor .hue-horizontal{
    border-radius: 10px !important;
}
.PopUp .Color .InputColor .div_input{
    width: 100%;
    position: relative;
    margin-bottom: 10px;
}
.PopUp .Color .InputColor .color_select{
    width: 32px;
    height: 32px;
    border-radius: 4px;
    position: absolute;
    top: 6px;
    left: 202px;
}
.PopUp .Color .InputColor .color_hex{
    width: 100%;
    display: flex;
    align-items: center;
}
.PopUp .Color .InputColor .color_rgb{
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}
.PopUp .Color .InputColor .color_rgb_div{
    height: 16px;   
    width: -webkit-fill-available;
    margin-top: 4px;
}
.PopUp .Color .InputColor .input_rgb{
    width: 28px;
    height: 30px;
    padding: 0px 6px;
    text-align: center;
}
.PopUp .Color .InputColor .svg_icon{
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.PopUp .Color .InputColor .div_select_type{
    text-align: left;
    display: flex;
    gap: 20px;
}
.PopUp .Color .InputColor .type{
    font-weight: 600;
    font-size: 16px;
}
.PopUp .Color .InputColor .div_button{
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
}
.PopUp .Color .InputColor .btn_click{
    width: 50%;
    flex-grow: 1;
    color: #FFFFFF;
    cursor: pointer;
    padding: 8px 16px;
    margin-top: 4px;
}
.PopUp .Color .InputColor .cancel{
    background: #E51D4D;
}
.PopUp .Color .InputColor .add_color{
    background: #3aa856;
}