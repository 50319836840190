html {
    scroll-behavior: smooth;
}
body, html {
    height: 100%;
}
body {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #6c757d;
    font-family: 'Poppins';
    background-color: #fff;
    overflow-x: hidden;
    letter-spacing: 0px;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}
p{
    padding: 0px;
    margin: 0;
}
svg {
    transition: all .2s cubic-bezier(.4, 0, 1, 1) 0s;
    cursor: pointer;
}
#root {
    width: 100%;
    height: 100%;
}
.container{
    max-width: 1200px;
    margin: 0% auto;
}
.icons {
    width: 24px;
    height: 24px;
    display: block;
}
.list_opt {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    gap: 6px;
}
ul {
    list-style: disc !important;
    padding-left: 18px;
    margin: 0px;
    padding: 0px;
}
ul li {
    margin-bottom: 20px;
}
.space_div {
    flex-grow: 1;
}
.div_color{
    width: 46px;
    height: 36px;
    border: 1px solid #000000;
}
.div_video {
    height: auto;
    width: 100%;
    aspect-ratio: 1.777;
    border: none;
}
::placeholder {
    color: #6c757d;
    font-size: 14px;
}
.page_released{
    display: flex;
    width: max-content;
    flex-grow: 1;
    gap: 6px;
    background-color: #061821;
    padding: 6px 10px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border: 2px solid #05496c;
    position: relative;
}
.page_released .div_show_select{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.page_released .div_delete{
    position: relative;
    top: 4px;
}
.no_data{
    text-align: center;
    font-style: italic;
    font-size: 12px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.div_opt_alt{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
}
.space_div_icon {
    width: 24px;
    height: 24px;
}
.hide_data {
    background-color: #24394c;
    color: #ffffff;
}
.div_delete_data{
    display: flex;
    position: relative;
    top: 2px;
}
.icon_user{    
    width: auto;
    height: 26px;
    display: flex;
    cursor: pointer;
}

/* scrollbar */
::-webkit-scrollbar {
    height: 6px;
    width: 4px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0px 14px #b7b7b7;
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0px 14px #111111;
}
/* end */


/* table */
table{
    padding-bottom: 10px;
    width: 100%;
}
table th{
    font-family: "Poppins";
    font-weight: 700;
    font-size: 12px;
    color: #ffffff;
    background-color: #061821;
    padding: 16px;
}
table tr{
    background-color: #ffffff;
}
table tr:nth-child(2n){
    background-color: #F6FAFF;
}
table td{
    padding: 16px;
    font-size: 12px;
}
/* end */


/* loading */
.return_data_save{
    position: absolute;
    left: 0;
    top: 0;
    background: #b3b3b36e;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
}
.div_loading{
    display: flex;
}
.loading{
    width: 60px;
    height: auto;
}
.return_data_save .dot-spinner {
    --uib-size: 2.8rem;
    --uib-speed: .9s;
    --uib-color: #183153;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
}
.return_data_save .dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}
.return_data_save .dot-spinner__dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}
.return_data_save .dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
}
.return_data_save .dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
}
.return_data_save .dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
}
.return_data_save .dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
}
.return_data_save .dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
}
.return_data_save .dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
}
.return_data_save .dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
}
.return_data_save .dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
}
.return_data_save .dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
}
.return_data_save .dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
}
.return_data_save .dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
}
.return_data_save .dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
}
.return_data_save .dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
}
.return_data_save .dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
}
@keyframes pulse0112 {
    0%, 100% {
        transform: scale(0);
        opacity: 0.5;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
}
/* end */


/* popup */
.PopUp {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8000;
    position: fixed;
    margin: 0% auto;
    overflow-y: auto;
    background: #FFF;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 50%);
    font-family: 'Inter';
}
.PopUpCenter{
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .all {
    width: 800px;
    height: auto;
    display: flex;
    text-align: center;
    margin: 2em auto 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    overflow-y: hidden;
    position: relative;
    z-index: 30;
    border: 1px solid #005FA1;
    margin-bottom: 40px;
    box-shadow: 0px 0px 8px 0px rgb(255 255 255 / 50%);
}
.PopUp .close_all{
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: 20;
    top: 0;
}
.PopUp .all .div_data {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding: 20px;
}
.PopUp .all .type_div{
    display: flex;
    text-align: center;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 20px 20px 16px;
    font-weight: bold;
    border-bottom:2px solid #324d6b;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
}
.PopUp .all .div_data .content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.PopUp .all .div_data .content .title_stage{
    width: 100%;
    margin-top: 10px;
}
.PopUp .all .div_data .content .opt_select{
    flex-grow: 1;
    width: auto;
    padding: 6px 10px;
    cursor: pointer;
}
.PopUp .all .div_data .content .yes_update {
    background-color: #76c57e;
}
.PopUp .all .div_data .content .not_update {
    background-color: #e95656;
}
/* end */


/* show_page_data */
.show_page_data{
    width: 100%;
    background-color: #ffffff;
}
.show_page_data:nth-child(1n){
    margin-top: 20px;
}
.show_page_data .type_title{
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    background: #2d3c53;
    color: #ffffff;
    padding: 10px 20px;
    font-family: 'inter';
    text-transform: uppercase;
}
.show_page_data .type_title div:nth-child(2){
    cursor: pointer;
}
.show_page_data .list_input_data{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    padding: 0px 20px 20px;
}
.show_page_data .list_input_data:nth-last-child(-n + 1){
    margin-bottom: 0px;
}
.show_page_data .show_list_border{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.show_page_data .show_list_border:nth-child(1){
    border-top: none;
}
.show_page_data .show_list_border:nth-child(n + 3){
    border-top: 1px solid #08374f;
    padding-top: 16px;
    margin-top: 16px;
}
.show_page_data table {
    color: #6c757d;
    padding-bottom: 0px;
}
.show_page_data table th {
    color: #6c757d;
    background-color: #ffffff;
    border-bottom: 1px solid #e2e8f0;
    padding: 10px 16px;
    font-size: 14px;
}
.show_page_data table td {
    padding: 6px 16px;
    font-size: 14px;
}
.show_page_data table td .Comp_Select{
    margin-top: 0;
}
.show_page_data table td .Comp_Select .css-13cymwt-control{
    border: 2px solid #061821 !important;
}
.show_page_data table td .Comp_Select .css-fdbcdi-singleValue{
    border-radius: 0px;
    color: #061821 !important;
}
.show_page_data table td .Comp_Select .css-t3ipsp-control{
    border: 2px solid #061821 !important;
}
.show_page_data table td .Comp_Select select,
.show_page_data table td .Comp_Select .css-13cymwt-control,
.show_page_data table td .Comp_Select .css-t3ipsp-control,
.show_page_data table td .Comp_Select .css-t3ipsp-control:hover {
    background-color: #ffffff;
}
.show_page_data table td .Comp_Select .css-1xc3v61-indicatorContainer{
    color: #061821 !important;
}
.show_page_data table td .Comp_Select .css-1u9des2-indicatorSeparator{
    background-color: #061821 !important;
}
/* end */


/* page_content */
.page_content{
    width: 100%;
    max-width: 1340px;
}
.page_content .Comp_Input .password{
    width: 120px;
}
.page_content .Comp_Select .access{
    min-width: 120px !important;
}
.page_content .Comp_Select .access_page{
    min-width: 170px !important;
}
.page_content .Comp_Select .type_page{
    min-width: 200px !important;
}
.page_content .Comp_Input .phone{
    width: 120px;
}
.page_content .Comp_Input .price{
    width: 80px;
    text-align: center;
}
.page_content .Comp_Input .new_payment{
    min-width: 230px;
}
.page_content .no_data{
    display: table-cell;
}
/* end */