.page_classes .Comp_Select .type_video{
    width: 130px;
    min-width: auto !important;
}
.page_classes .Comp_Select .type_release{
    width: 220px;
    min-width: auto !important;
}
.page_classes .Comp_Select .type_category{
    width: 300px;
    min-width: auto !important;
}
.page_classes .Comp_Input .qtd_day{
    width: 80px;
    text-align: center
}
.page_classes .Comp_Input .input_code{
    width: 106px;
    text-align: center
}
.page_classes .list_input_data{
    padding: 0px 20px;
    margin-bottom: 10px;
}
.page_classes .list_input_data:nth-last-child(-n + 1){
    padding: 0px 20px 20px;
    margin-bottom: 20px;
}
.page_classes table th{
    color: #ffffff;
}
.page_classes table .Comp_Input{
    margin-top: 0;
}
.page_classes table .Comp_InputFile{
    margin-top: 0;
    justify-content: flex-start;
}
.page_classes .show_page_data table th{
    padding: 10px 6px;
}
.page_classes .show_page_data table td{
    padding: 10px 6px;
}