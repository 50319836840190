.PopUp .PhotoCrop{
    width: 600px;
}
.PopUp .PhotoCrop .content{
    overflow: hidden !important;
}
.PopUp .PhotoCrop .image{
    width: 100%;
    max-height: 600px;
}

.PopUp .PhotoCrop .div_type_crop{
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.PopUp .PhotoCrop .div_type_crop .type_crop{
    padding: 10px;
    border: 1px solid #2D3C53;
    border-radius: 6px;
    cursor: pointer;
    flex-grow: 1;
}
.PopUp .PhotoCrop .div_type_crop .crop_open{
    background-color: #2D3C53;
    color: #FFFFFF;
}
.PopUp .PhotoCrop .new_block_text{
    margin-top: 30px;
}

.PopUp .PhotoCrop .btn_save{
    width: 100%;
    height: 40px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    padding: 0;
    font-size: 14px;
    font-family: 'Poppins';
    background-color: #3aa856;
}