.Comp_InputFile{
    position: relative;
    margin-top: 8px;
    color: #424242;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Comp_InputFile input[type="file"]{
    display: none;
}
.Comp_InputFile label{
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background: #2d3c53;
    color: #ffffff;
    height: 41px;
    padding: 0px 14px;
    border-radius: 8px;
    cursor: pointer;
}
.Comp_InputFile .label_active{
    background: #ffc400;
    border-radius: 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.Comp_InputFile .show_file_{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.Comp_InputFile .div_remuve{
    height: 41px;
    background: rgb(7, 196, 214);
    padding: 0px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;
}
.Comp_InputFile .show_img{
    padding-left: 6px;
    cursor: pointer;
}
.Comp_InputFile .show_img .img_thumbnail{
    width: auto;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}