.PopUp .ReturnResponse{
    width: 360px;
    margin: 0;
}
.PopUp .ReturnResponse .icons_return{
    width: 200px;
    height: 200px;
    cursor: default;
}
.PopUp .ReturnResponse .title_return{
    font-size: 16px;
    font-family: 'Inter';
}
.PopUp .ReturnResponse .div_confirmation{
    flex-direction: row;
    margin-top: 16px;
}
.PopUp .ReturnResponse .close_return{
    background-color: #E1005A;
    color: #ffffff;
    width: 100%;
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'inter';
    cursor: pointer;
    margin-top: 20px;
    border-radius: 6px;
}
