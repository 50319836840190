.Contents{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    background: #eef0f5;
    overflow: hidden;
    transition: .5s;
}
.Contents .show_site{
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 888;
    background-color: #324d6b;
    padding: 14px 4px 4px 14px;
    border-top-left-radius: 52px;
}
.Contents .show_site .icons_site{
    display: flex;
    width: 20px;
    height: 20px;
}

.Contents .div_menu_opt{
    width: 220px;
    max-width: 220px;
    min-width: 220px;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 99;
}
.Contents .menu_close {
    width: 60px;
    max-width: 60px;
    min-width: 60px;
    transform: translateX(0px) translateY(0px);
}
.Contents .div_menu_opt .div_logo{
    width: 220px;
    max-width: 220px;
    min-width: 220px;
    height: 90px;
    max-height: 90px;
    min-height: 90px;
    background-color: #2d3c53;
    border-bottom: 1px solid #DFDFDF;
    cursor: pointer;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
}
.Contents .div_menu_opt .div_logo_alt_width{
    width: auto;
    max-width: inherit;
    min-width: inherit;
}
.Contents .div_menu_opt .div_logo .open_or_close_menu{
    width: 28px;
    height: 28px;
    background: #ffc400;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    position: absolute;
    right: -14px;
}
.Contents .div_menu_opt .div_logo .alt_position_icons{
    right: auto;
}
.Contents .div_menu_opt .div_logo .open_or_close_menu .icons{
    width: 16px;
    height: 16px;
}
.Contents .div_menu_opt .div_logo .open_or_close_menu .icon_close{
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: .5s;
}
.Contents .div_menu_opt .div_logo .logotipo{
    width: auto;
    height: 50px;
}
.Contents .div_menu_opt .div_logo .logo_hide{
    display: none;
}

.Contents .div_menu_opt .show_menu{
    width: 220px;
    max-width: 220px;
    min-width: 220px;
    height: auto;
    padding: 20px 0px;
    flex-grow: 1;
    background-color: #2d3c53;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    transition: .5s;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.Contents .div_menu_opt .show_menu_alt_width{
    width: auto;
    max-width: inherit;
    min-width: inherit;
}
.Contents .div_menu_opt .show_menu .show_menu_dash {
    padding: 10px 20px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
    width: -webkit-fill-available;
}
.Contents .div_menu_opt .show_menu .show_menu_dash:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.2s;
}
.Contents .div_menu_opt .show_menu .active_dash, 
.Contents .div_menu_opt .show_menu .active_dash:hover{
    background-color: rgba(7, 196, 214, .5);
    border-right: 4px solid #ffc400;
    -webkit-transform: none;
    transform: none;
}
.Contents .div_menu_opt .show_menu .show_menu_dash_ {
    padding: 10px;
}
.Contents .div_menu_opt .show_menu .sub_menu .show_menu_dash{
    padding: 6px;
}
.Contents .div_menu_opt .show_menu .show_menu_dash .name_menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: -webkit-fill-available;
    font-size: 13px;
    gap: 10px;
    white-space: nowrap;
}
.Contents .div_menu_opt .show_menu .show_menu_dash .name_menu_ {
    justify-content: center;
}
.Contents .div_menu_opt .show_menu .show_menu_dash .name_menu .div_icons{
    display: flex;
}
.Contents .div_menu_opt .show_menu .show_menu_dash .name_menu .div_icons .icons_dash{
    width: 24px;
    height: 24px;
    display: block;
}
.Contents .div_menu_opt .show_menu .show_menu_dash .name_menu .title_close{
    display: none;
}

.Contents .div_menu_opt .div_inf_profile{
    width: 200px;
    max-width: 200px;
    min-width: 200px;
    height: 50px;
    max-height: 50px;
    min-height: 50px;
    padding: 0px 10px 10px;
    background-color: #2d3c53;
    transition: .5s;
}
.Contents .div_menu_opt .div_inf_profile_alt_width{
    width: auto;
    max-width: inherit;
    min-width: inherit;
    padding: 0;
}
.Contents .div_menu_opt .div_inf_profile .show_name_user{
    width: calc(100% - 32px);
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(106, 133, 174, .3);
    border-radius: 6px;
    padding: 0px 16px;
    color: #ffffff;
    cursor: pointer;
}
.Contents .div_menu_opt .div_inf_profile_alt_width .show_name_user{
    border-radius: 0px;
}
.Contents .div_menu_opt .div_inf_profile .show_name_user .name_user {
    color: #ffffff;
    font-family: 'inter';
    font-weight: bold;
    text-align: center;
}
.Contents .div_menu_opt .div_inf_profile .show_name_user .title_close{
    display: none;
}
.Contents .div_menu_opt .div_inf_profile .show_name_user .div_img {
    display: flex;
}
.Contents .div_menu_opt .div_inf_profile .show_name_user .div_img .show_img_profile{
    width: 32px;
    height: 32px;
    border-radius: 32px;
}

.Contents .div_data_page{
    width: calc(100% - 60px);
    height: calc(100% - 100px);
    overflow-y: auto;
    background: #eef0f5;
    padding: 30px 30px 70px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

@media only screen and (max-width: 800px) {
    .Contents{
        flex-direction: column;
    }
    .Contents .div_menu_opt{
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;

        min-height: 60px !important;
        position: absolute !important;
        overflow: hidden !important;
        height: auto;
    }
    .Contents .div_data_page{
        width: calc(100% - 40px);
        height: calc(100% - 140px);
        padding: 120px 20px 20px;
    }
    .Contents .menu_close{
        height: 100%;
    }
    .Contents .div_menu_opt .div_logo{
        justify-content: space-between;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: 60px;
        z-index: 999;
    }
    
    .Contents .div_menu_opt .div_logo .logotipo{
        padding-right: 20px;
        height: 32px;
    }
    .Contents .div_menu_opt .div_logo .logo_hide{
        display: block;
    }
    .Contents .div_menu_opt .div_logo .open_or_close_menu{
        position: relative;
        margin-left: 20px;
        right: inherit;
    }
    .Contents .div_menu_opt .show_menu{
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        min-height: calc(100% - 171px);
        position: absolute;
        top: 88px;
        padding: 20px 0px;
        left: -101%;
    }
    .Contents .div_menu_opt .show_menu_alt_width{
        left: 0;
    }   
    .Contents .div_menu_opt .show_menu .show_menu_dash{
        padding: 10px 30px;
    }
    .Contents .div_menu_opt .show_menu .show_menu_dash .name_menu .title_close{
        display: block;
        flex-grow: 1;
    }
    .Contents .div_menu_opt .div_inf_profile{
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        min-height: 70px;
        overflow-y: hidden;
        padding: 0;

        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
        left: -101%;
    }
    .Contents .div_menu_opt .div_inf_profile_alt_width{
        left: 0;
    }
    .Contents .div_menu_opt .div_inf_profile .show_name_user{
        width: calc(100% - 60px);
    }
    .Contents .div_menu_opt .div_inf_profile .show_name_user .title_close{
        display: block;
    }
}