.page_home .div_show_btn{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.page_home .div_show_btn:nth-child(n + 2) {
    padding-top: 20px;
    margin-top: 18px;
    border-top: 2px solid #ebebeb;
}